import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {MatDialogActions, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {fromEvent, pairwise} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {VisitStatusEnum} from 'src/app/model/enums/visit-status.enum';
import {Visit} from 'src/app/model/visit/visit.model';
import {ExportWebservice} from 'src/app/services/webservices/export.webservice';
import {FamilyVisitWebService} from 'src/app/services/webservices/familyvisit.webservice';
import {Destroyed} from '../../directives/destroyed.directive';
import {SignatureVisitModel} from 'src/app/model/event/signature-visit.model';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-dialog-previsualize-visit-pdf',
  templateUrl: './dialog-previsualize-visit-pdf.component.html',
  styleUrls: ['./dialog-previsualize-visit-pdf.component.scss'],
  standalone: true,
  imports: [NgIf, NgxExtendedPdfViewerModule, MatDialogContent, MatDialogActions]
})
export class DialogPrevisualizeVisitPdfComponent extends Destroyed implements AfterViewInit {
  @Input() visit: Visit;
  @Input() visitId: number;
  @Input() pdf: Blob;

  @ViewChild('canvasRI') canvasRI: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasRL') canvasRL: ElementRef<HTMLCanvasElement>;
  step: number = 0;
  isRISigned = false;
  isRLSigned = false;
  isSaveBtnLoading = false;
  isSaveError: boolean | null = null;
  lastPos: {x: number; y: number} = {x: 0, y: 0};
  private ctxRI: CanvasRenderingContext2D;
  private ctxRL: CanvasRenderingContext2D;

  constructor(
    public readonly dialogRef: MatDialogRef<DialogPrevisualizeVisitPdfComponent>,
    private readonly exportWebService: ExportWebservice,
    private readonly visitFamilyWebService: FamilyVisitWebService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.ctxRI = this.canvasRI.nativeElement.getContext('2d');
    this.ctxRL = this.canvasRL.nativeElement.getContext('2d');
  }

  //#endregion

  //#region Events

  onBtnNextClick(): void {
    if (this.step < 3) {
      this.step++;
    }
    switch (this.step) {
      case 1:
        this.captureEvents(this.canvasRI, this.ctxRI);
        break;
      case 2:
        this.captureEvents(this.canvasRL, this.ctxRL);
        break;
      case 3:
        this.signVisitPdf();
        break;
      default:
        break;
    }
  }

  onBtnClearClick(): void {
    switch (this.step) {
      case 1:
        this.ctxRI.clearRect(
          0,
          0,
          this.canvasRI.nativeElement.width,
          this.canvasRI.nativeElement.height
        );
        this.isRISigned = false;
        break;
      case 2:
        this.ctxRL.clearRect(
          0,
          0,
          this.canvasRL.nativeElement.width,
          this.canvasRL.nativeElement.height
        );
        this.isRLSigned = false;
        break;
      default:
        break;
    }
  }

  onBtnCancelClick(): void {
    this.dialogRef.close();
  }

  private captureEvents(canvas: ElementRef<HTMLCanvasElement>, ctx: CanvasRenderingContext2D) {
    const canvasEl = canvas.nativeElement;
    canvasEl.height = window.innerHeight / 2;
    canvasEl.width = Math.min(window.innerWidth * 0.9, 800);

    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;
    ctx.lineJoin = 'round';

    // TOUCH EVENTS
    const touchStart = fromEvent(canvasEl, 'touchstart');
    const touchMove = fromEvent(canvasEl, 'touchmove');
    const touchEnd = fromEvent(canvasEl, 'touchend');

    touchStart
      .pipe(
        switchMap((e) => {
          e.preventDefault();
          // after a mouse down, we'll record all mouse moves
          return touchMove.pipe(
            // we'll stop (and unsubscribe) once the user releases the mouse
            // this will trigger a 'mouseup' event
            takeUntil(touchEnd),
            // pairwise lets us get the previous value to draw a line from
            // the previous point to the current point
            pairwise()
          );
        })
      )
      .subscribe((res: [TouchEvent, TouchEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset
        const prevPos = {
          x: res[0].touches[0].clientX - rect.left,
          y: res[0].touches[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].touches[0].clientX - rect.left,
          y: res[1].touches[0].clientY - rect.top
        };

        // this method we'll implement soon to do the actual drawing
        this.drawOnCanvas(ctx, prevPos, currentPos);
      });

    // MOUSE EVENTS
    const mouseDown = fromEvent(canvasEl, 'mousedown');
    const mouseMove = fromEvent(canvasEl, 'mousemove');
    const mouseUp = fromEvent(canvasEl, 'mouseup');
    const mouseLeave = fromEvent(canvasEl, 'mouseleave');

    // this will capture all mousedown events from the canvas element
    mouseDown
      .pipe(
        switchMap((e) => {
          // after a mouse down, we'll record all mouse moves
          return mouseMove.pipe(
            // we'll stop (and unsubscribe) once the user releases the mouse
            // this will trigger a 'mouseup' event
            takeUntil(mouseUp),
            // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
            takeUntil(mouseLeave),
            // pairwise lets us get the previous value to draw a line from
            // the previous point to the current point
            pairwise()
          );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

        // this method we'll implement soon to do the actual drawing
        this.drawOnCanvas(ctx, prevPos, currentPos);
      });
  }

  private drawOnCanvas(
    ctx: CanvasRenderingContext2D,
    prevPos: {x: number; y: number},
    currentPos: {x: number; y: number}
  ) {
    if (!ctx) {
      return;
    }

    ctx.beginPath();

    if (this.step === 1) {
      this.isRISigned = true;
    } else if (this.step === 2) {
      this.isRLSigned = true;
    }

    if (prevPos) {
      ctx.moveTo(prevPos.x, prevPos.y); // from
      ctx.lineTo(currentPos.x, currentPos.y);
      ctx.stroke();
    }
  }

  private signVisitPdf(): void {
    this.isSaveError = null;
    this.isSaveBtnLoading = true;

    const signatureRI = this.canvasRI.nativeElement.toDataURL();
    const signatureRL = this.canvasRL.nativeElement.toDataURL();
    const signatures = new SignatureVisitModel(signatureRI, signatureRL);

    this.exportWebService
      .signPDFVisite(this.visitId, signatures)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: () => {
          this.visitFamilyWebService
            .signVisit(this.visitId)
            .pipe(this.untilDestroyed())
            .subscribe({
              next: () => {
                this.visit.status = VisitStatusEnum.SIGNED;
                this.dialogRef.close();
              },
              error: () => {
                this.isSaveError = true;
                this.step = 2;
              }
            })
            .add(() => (this.isSaveBtnLoading = false));
        },
        error: () => {
          this.isSaveError = true;
          this.step = 2;
          this.isSaveBtnLoading = false;
        }
      });
  }
}
